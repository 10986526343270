// import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import { resApi } from './resApi';

export const heroApi = resApi.injectEndpoints({
    overrideExisting: true,
    endpoints: (builder) => ({
        nftHero: builder.query({
            query: (heroid) => `/amir/nft/hero/${heroid}`,
        }),
        heroes: builder.query({
            query: () => `/amir/nft/heroes/cookie`,
        }),
        heroesExtra: builder.query({
            query: () => `/amir/hero/extra/cookie`,
        }),
        heroesOnland: builder.query({
            query: (heroid) => `/amir/hero/onland/cookie/${heroid}`,
        }),
       
    }),
});

export const {useNftHeroQuery, useHeroesQuery, useHeroesExtraQuery, useHeroesOnlandQuery} = heroApi;