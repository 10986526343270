import {useState, useEffect} from 'react'
import { Stack, Typography, Button } from '@mui/material'
import WOCPAD from '../../utils/blockchain/WOCPAD';
import WOCLANDMANAGER from '../../utils/blockchain/WOCLANDMANAGER';
import img from '../../assets/img/forms/castle_on_the_river.jpeg'
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import LandImageCycle from './components/LandImageCycle'
import {useNftLandQuery} from '../../redux/api/landApi'
import {landImages} from '../../assets/img/lands'
const debug = true;

 const ExchLand = () => {

    const [coinSymbol, setCoinSymbol] = useState('');
    const [coinBalance, setCoinBalance] = useState(0);
    const [landCost, setHeroCost] = useState(0);
  
    const [modalVisible, setModalVisible] = useState(false);
    const [mintedLandId, setMintedLandId] = useState(undefined)
  
    const { data, isFetching, isLoading  } = 
      useNftLandQuery(mintedLandId, {
        skip: !mintedLandId, 
        pollingInterval: 1000,
      });
  
    useEffect(() => {
      async function fetchData() {
        setCoinSymbol(await WOCPAD.getCoinToSymbol());
        const bigCoinToBalance = Number(await WOCPAD.toCoinBalance())
        setCoinBalance(parseFloat(bigCoinToBalance/10**18));
        setHeroCost(Number(await WOCLANDMANAGER.getLandCost())/10**18);   
      }
      fetchData()
    }, []);
    
  
    const handleClose = () => {
      setModalVisible(false);
      setMintedLandId(undefined);
    };
  
    return (
      <>
      <Stack spacing={2} direction='row' justifyContent='space-between'>
        <Stack direction='column' sx={{ flex: 1}}>
          <Typography variant='h6'>You can easily get involved in testing 
          Mint Land</Typography>
          <Typography>
            1. You should have at least {landCost} {coinSymbol}. <br/>
            If you do not have enough you could go buy on Platinum page
          </Typography>
          <Typography>
            2. Just click MINT button. You will be asked for approval and then for mint itself.
          </Typography>
        </Stack>
        <Stack direction='column' alignItems="center" justifyContent="center" sx={{ flex: 1}}>
            <Button 
                variant="contained" 
                color="secondary" 
                onClick={() => {
                  async function mintLand() {
                    const ret = await WOCLANDMANAGER.requestApproval();
                    if (ret) {
                      setModalVisible(true);
                      const landId = await WOCLANDMANAGER.mint4ptc();
                      setMintedLandId(landId)
                      setCoinBalance(coinBalance-landCost);
                      debug && console.log('mintedLandId', landId)
                    }
                  }
                  mintLand()
                }}
            >Mint Land</Button>
            <div style={{marginLeft:'10px'}}>
                {coinSymbol} {Number(coinBalance).toFixed(2)}
            </div>
        </Stack>
        <Stack sx={{ flex: 1}}>
          <img src={img} alt='land' width={400} height={400}/>
        </Stack>
      </Stack>
      
      <Dialog open={modalVisible} onClose={handleClose}>
          <DialogTitle>Minting Land</DialogTitle>
          <DialogContent>
            <Stack spacing={2} direction='column' alignItems="center" justifyContent="center">
              {
                mintedLandId > 0 && data ?
                <>
                <Typography>
                  <img src={landImages[data.terrain_type]} alt='land' width={200} height={200}
                  />
                </Typography>
                <Typography variant='h4'>
                  #{data.landid} ({data.x}, {data.y})
                </Typography>
                </>
                 :
                <LandImageCycle/>
              }
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </>
    )
}

export default ExchLand