import {AppBar, Toolbar, IconButton, Stack, Button, Typography} from '@mui/material';

export const Footer = () => {
  return (
    <Stack direction='column' alignItems={'center'}>
   <Toolbar>
    <Stack direction='column'>
        <Button href='https://warofcontinents.online'>web</Button>
        <Button href='https://warofcontinents.online/docs/intro'>doc</Button>
        <Button href='https://warofcontinents.online/blog'>blog</Button>
    </Stack>
   </Toolbar>
    <Typography>Copyright © 2024 War of Continents.</Typography>
    </Stack>
  )
}
