import {useState} from 'react'
import {
    Stack,
    Button,
    Box,
    Typography,
    Tooltip,
    Link
} from '@mui/material'
import LandCard from './components/LandCard'
import StartHeroTransferMission from '../StartMission/StartHeroTransferMission'
import StartSearchElementalsMission from '../StartMission/StartSearchElementalsMission'
import img from '../../assets/img/forms/land_vault.png'
import { elementImg } from '../../assets/img/elements'
import {elementTypes} from '../../assets/consts/land_and_hero_attributes'
import {useVaultQuery} from '../../redux/api/landApi'

const debug=true;

const LandVault = ({land}) => {
    const [openHeroTransferMission, setOpenHeroTransferMission] = useState(false)
    const [openSearchElementalsMission, setOpenSearchElementalsMission] = useState(false)
    const {data, isLoading, isSuccess, isFetching, isUninitialized} = useVaultQuery(land?.landid, {
        skip: !land?.landid, 
        pollingInterval: 60000,
      })
    debug && console.log('LandVault:', 'data', data, 'isLoading', isLoading, 'isSuccess', isSuccess, 'isFetching', isFetching, 'isUninitialized', isUninitialized)
    if(isLoading || isFetching) return 'Loading...'
    debug && console.log('LandVault: data:', data)
    return (<>
        <Stack spacing={10} direction='row' width="100%">
        <Box sx={{ 
                flex: 0, 
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                }}> 
                <LandCard land={land}/>
                <Button variant='outlined' onClick={() => setOpenHeroTransferMission(true)} sx={{ width: 200 }}>Transfer hero</Button>

            </Box>
            <Box sx={{ 
                flex: 1, 
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                }}>
             {isSuccess && !data?
                <div style={{flex: 1, display: 'flex',justifyContent: 'center', alignItems: 'center'}}>
                    <Typography color='primary' variant='h4'>Send Hero for elementals </Typography>
                </div>
             :   data.volume.map((item, index) => (
                    <Box sx={{ 
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        }}
                        key={index}
                        >
                        <Tooltip title={<>{elementTypes[index]} Vault keeps yours elementals. For more details follow <Link href="https://warofcontinents.online/docs/roadmap/epoch1" target="_blank">the link</Link></>} placement='top'>
                            <img src={elementImg[index]} height={50} with={50} style={{ marginRight: 16 }} alt='element' />
                        </Tooltip>
                        <Typography color='secondary' variant='h4'>{item}</Typography>
                    </Box>
                ))
             }
        </Box>
            <Box  sx={{ 
                flex: 1, 
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                }}>
                    <Button variant='outlined'  onClick={() => setOpenSearchElementalsMission(true)} sx={{ width: 250 }}>Go and find elementals! </Button>

            </Box>
            <Box sx={{ flex: 1 }}>
                <img src={img} height={300} width={300} style={{borderRadius:'10px', border:'1px solid', 'borderColor':'gray'}} alt='gold storage' />
            </Box>
    </Stack>
    <StartHeroTransferMission open={openHeroTransferMission} setOpen={setOpenHeroTransferMission} landid={land.landid}/>
    <StartSearchElementalsMission open={openSearchElementalsMission} setOpen={setOpenSearchElementalsMission} landid={land.landid}/>
    </>
  )
}

export default LandVault    