import React from 'react';
import './LoginPage.css';
import  { useState } from 'react';
import { useDispatch } from 'react-redux';
import { userloginByUsername, userlogout } from '../../redux/slices/authSlice';

const debug = true;


const LoginPage = () => {
  debug && console.log('LoginPage function');
  const dispatch = useDispatch();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

    const handleLogin = async (event) => {
      debug && console.log('Login button clicked with username', username, 'and password', password);
      event.preventDefault();
      setError('');
      dispatch(userloginByUsername({username, password}));
    };

    const handleLogout = async () => {
      debug && console.log('Logout button clicked');
      setError('');
      dispatch(userlogout());
    }

    return (
      <div className="login-page"> 
        <form className="login-form" onSubmit={handleLogin}>
          <h2>Login</h2>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input type="text" id="username" name="username" value={username} onChange={(e) => setUsername(e.target.value)}/>
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input type="password" id="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
          </div>
          <div className="button-group">
            <button type="submit">Login</button>
            <button type="button" onClick={handleLogout}>Logout</button>
          </div>
          {error && <div style={{ color: 'red' }}>{error}</div>}
        </form>
      </div>      
    );
}

export default LoginPage;