import HeroImage from "./HeroImage"
const {elementImg} = require('../../../assets/img/elements')

const HeroImageAndElement = ({hero}) => {
    return (
        <div style={{
            position: 'relative',
            display: 'inline-block'
        }}>
            <HeroImage sex={hero.sex} rarity={hero.rarity}/>
            <img src={elementImg[hero.element]} style={{
                position: 'absolute',
                top: 10,
                right: 10,
                width: '50px',
                height: '50px',
                // objectFit: 'cover',
                // mixBlendMode: 'multiply',
                // opacity: 0.5
            }}
            />
        </div>
    )
}

export default HeroImageAndElement