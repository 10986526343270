import {IconButton, Stack, Badge} from '@mui/material';
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom';
import {useGetUnreadQtyQuery} from '../../redux/api/messagesApi';

import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import MapIcon from '@mui/icons-material/Map';
import CastleIcon from '@mui/icons-material/Castle';
import Person4Icon from '@mui/icons-material/Person4';
import ScienceIcon from '@mui/icons-material/Science';
import BalanceIcon from '@mui/icons-material/Balance';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const mainMenu = [
    {order: 10, icon: EmojiEventsIcon, label: 'Dashboard', path: '/leaderboard', permission: 'EPOCH1'},
    {order: 20, icon: MapIcon, label: 'Map', path: '/leaderboard', permission: 'EPOCH2'},
    {order: 30, icon: CastleIcon, label: 'Lands', path: '/lands', permission: 'EPOCH1'},
    {order: 40, icon: Person4Icon, label: 'Heroes', path: '/heroes', permission: 'EPOCH1'},
    {order: 50, icon: ScienceIcon, label: 'Technology', path: '/leaderboard', permission: 'EPOCH3'},
    {order: 60, icon: BalanceIcon, label: 'Exchange', path: '/exchange/start', permission: 'EPOCH1'},
    {order: 70, icon: AccountBalanceWalletIcon, label: 'Balances', path: '/balances', permission: 'EPOCH1'},
    {order: 80, icon: NotificationsIcon, label: 'Notifications', path: '/messages/default', permission: 'LOGGEDIN'},
    {order: 90, icon: SettingsIcon, label: 'Settings', path: '/leaderboard', permission: 'PUBLIC'},
]


export const NavbarElements = ({setModule}) => {
    const {data=[], isLoading} = useGetUnreadQtyQuery(
        // { pollingInterval: 30000 }
);
    let notificationsQty = 0
    if(!isLoading) notificationsQty = data.unreadMessages;

    const navigate = useNavigate();
    const permissions = useSelector(state => state.auth.permissions);
    const filteredMenu = mainMenu.filter(item => permissions.includes(item.permission));
    const updatedMenu = filteredMenu.map(item => {
        const badgeContent = item.icon === NotificationsIcon ? notificationsQty : 0;
        return {
            ...item,
            badgeContent
        };
    });
    const menu = updatedMenu.sort((a, b) => a.order - b.order);

    return (
        <Stack direction='row' spacing={2}>
        {menu.map((item) => (
            <IconButton 
                key={item.order} 
                color='secondary' 
                onClick={() => {
                    setModule(item.label);
                    navigate(item.path)
                }}
                style={{ width: 'auto'}}
            >
                <Badge badgeContent={item.badgeContent} color="primary">
                    <item.icon/>
                </Badge>
            </IconButton>
        ))} 
    </Stack>
  )
}
