import React from 'react'
import {useMedalsNowQuery} from '../../redux/api/musabikApi'
import {Table,
        TableBody,
        TableCell,
        TableContainer,
        TableHead,
        TableRow,
        Paper
} from '@mui/material';

const debug = true;

const MyMedals = () => {
  const { data=[], isLoading } = useMedalsNowQuery()
  if (isLoading) return 'Loading...'

  // console.log(data)

  return (
    <TableContainer>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Medals</TableCell>
          <TableCell align="right">Today</TableCell>
          <TableCell align="right">Week</TableCell>
          <TableCell align="right">Month</TableCell>
          <TableCell align="right">Year</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          data?.map((medal) => (
            <TableRow key={medal.asset_code}>
              <TableCell component="th" scope="row">
                {medal.asset_name}
              </TableCell>
              <TableCell align="right">{medal.day}</TableCell>
              <TableCell align="right">{medal.week}</TableCell>
              <TableCell align="right">{medal.month}</TableCell>
              <TableCell align="right">{medal.year}</TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
    </TableContainer>

  )
}

export default MyMedals