import {useState} from 'react'
import {
    Stack,
    Button,
    Box,
    Typography,
    Tooltip,
    TablePagination,
} from '@mui/material'
import {useHeroesOnlandQuery} from '../../redux/api/heroApi'

import LandCard from './components/LandCard'
import StartHeroTransferMission from '../StartMission/StartHeroTransferMission'

import HeroCardSimple from '../Heroes/components/HeroCardSimple'

const debug = true;


const LandHeroes = ({land}) => {
    const { data=[], isLoading, isSuccess } = useHeroesOnlandQuery(land.landid)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [openHeroTransferMission, setOpenHeroTransferMission] = useState(false)

    if (isLoading)  {
        return (<div>Loading...</div>)
    }

    console.log(data)
      const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <>
        <Stack spacing={10} direction='row'>
            <Box sx={{ 
                flex: 0, 
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',

            }}> 
                <LandCard land={land} />
                <Button variant='outlined' onClick={() => setOpenHeroTransferMission(true)} sx={{ width: 200 }}>Transfer hero</Button>


            </Box>
            <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            }}> 
                <Box sx={{ 
                flex: 1, 
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap'
                }}> 
                    {    
                    data.heroes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((hero) => {
                    return (<HeroCardSimple key={hero.heroid} hero={hero}/>)}) 
                    }
                </Box>
                <Box sx={{ 
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                }}>            
                    <TablePagination
                        component="div"
                        count={data.heroes.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[5]}
                    />
                </Box>
            </Box>
        </Stack>
        <StartHeroTransferMission open={openHeroTransferMission} setOpen={setOpenHeroTransferMission} landid={land.landid}/>
        </>
  )
}


export default LandHeroes