import ColoredRarityText from '../../../components/nft/ColoredRarityText'
import LandImageAndElement from './LandImageAndElement'

const LandCard = ({land}) => {
  return (
    <div style = {{margin:'5px', width: '200px',}}>
        <ColoredRarityText rarity={land.terrain_type} text={` #${land.landid}: ${land.x}-${land.y}`}/>
        <LandImageAndElement land={land}/>
        <ColoredRarityText rarity={land.terrain_type} text={` R${land.resource_slots}/B${land.building_slots}`}/>
    </div>
)
}

export default LandCard 