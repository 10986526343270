import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice'
import { resApi } from './api/resApi';


export const store = configureStore({
    reducer: {
        auth: authReducer,
        [resApi.reducerPath]: resApi.reducer

    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(resApi.middleware),
})