import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const resApi = createApi({
    reducerPath: 'resApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://res.warofcontinents.online/',
        credentials: 'include',
    }),
    tagTypes: ['messages', 'unreadqty'],
    endpoints:() => ({}),

});
