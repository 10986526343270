import {
    Button,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Snackbar
} from '@mui/material';
import { useState } from 'react';

import {useStartMissionMutation} from '../../redux/api/misaarApi'

import HeroesOnlandButtonsList from './components/HeroesOnlandButtonsList';

const StartSearchElementalsMission = ({open, setOpen, landid}) => {
    const [selectedHero, setSelectedHero] = useState(null);
    const [notify, setNotify] = useState(false);

    const [startMission] = useStartMissionMutation();

    const handleDialogClose = () => {
        setOpen(false);
    }
    const handleDialogStart = () => {
      if (selectedHero) {
         startMission({missiontype:'SEARCHELEMS',
          body:{ heroid: selectedHero.heroid, landid }})
      }
        setNotify(true)
      }

      const handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setNotify(false);
      };
    
  return (
    <Dialog open={open} onClose={handleDialogClose}>
    <DialogTitle sx={{ textAlign: 'center' }}>Search Elementals Mission</DialogTitle>
    <DialogContent>
    <Box
      sx={{
        display: 'flex', 
        flexDirection: 'row',
        alignItems: 'stretch', 
        height: '100%',
      }}
    >
      <Box
        sx={{
          flex: 1, // Первый элемент занимает равное пространство
        }}
      >
        <HeroesOnlandButtonsList setSelectedHero={setSelectedHero} landid={landid} noHeroMsg="No heroes at this Land"/>
      </Box>
      <Box
        sx={{
            flex: 1, // Второй блок занимает оставшееся пространство
            display: 'flex', // Flexbox для центрирования текста
            justifyContent: 'center', // Центрирование по горизонтали
            alignItems: 'center', // Центрирование по вертикали
        }}
      >
          {
            selectedHero
              ? <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center'
              }}>
                <DialogContentText>
                Objectives:
                </DialogContentText>
                <DialogContentText variant='h5' color='secondary'>
                Send Hero #{selectedHero.heroid} to search Elementals at the Land #{landid}
                </DialogContentText>
              </Box>
              : <DialogContentText>Select a hero to start the mission</DialogContentText>
          }
      </Box>
    </Box>
    </DialogContent>
    <DialogActions>
        <Button onClick={handleDialogStart}>Start</Button>
        <Snackbar open={notify} 
                autoHideDuration={3000} 
                onClose={handleCloseNotification}
                message = {`Hero #${selectedHero?.heroid} has been send for search Elementals at the Land #${landid}`}/>
      <Button onClick={handleDialogClose}>Cancel</Button>
    </DialogActions>
    </Dialog>
  )
}

export default StartSearchElementalsMission