import { resApi } from './resApi';

export const misaarApi = resApi.injectEndpoints({
    overrideExisting: true,
    endpoints: (builder) => ({
        startMission: builder.mutation({
            query: ({missiontype, body}) => ({
                url:`/misaar/start/mission/${missiontype}`,
                method: 'POST',
                body,})
        }),

    }),
});

export const {useStartMissionMutation} = misaarApi;