const {landImages} = require('../../../assets/img/lands')

const LandImage = ({terrain_type}) => {
    if(terrain_type<0 || terrain_type>4) return null
    return (
        <img src={landImages[terrain_type]} style={{
            width: '200px',
            height: '200px',
            borderRadius: '9px'
          }}  alt="Land card image" loading="lazy"/>
  )
}

export default LandImage