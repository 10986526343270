import h0f from './woc-hero-0-female-common-500x875.png'
import h0m from './woc-hero-0-male-common-500x875.png'
import h1f from './woc-hero-1-female-uncommon-500x875.png'
import h1m from './woc-hero-1-male-uncommon-500x875.png'
import h2f from './woc-hero-2-female-rare-500x875.png'
import h2m from './woc-hero-2-male-rare-500x875.png'
import h3f from './woc-hero-3-female-epic-500x875.png'
import h3m from './woc-hero-3-male-epic-500x875.png'
import h4f from './woc-hero-4-female-legendary-500x875.png'
import h4m from './woc-hero-4-male-legendary-500x875.png'

export const heroImages = [
    [h0f, h1f, h2f, h3f, h4f],
    [h0m, h1m, h2m, h3m, h4m]
];