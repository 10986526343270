import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from 'axios';
import Cookies from 'js-cookie';
import {getCurrentUserDataFromToken} from '../../utils/accessTokenHelper';




const debug = false;
axios.defaults.withCredentials = true;

const AUTH_LOGIN_URL = 'https://auth.warofcontinents.online/auth/login';
const AUTH_LOGOUT_URL = 'https://auth.warofcontinents.online/auth/logout';
const AUTH_REFRESH_URL = 'https://auth.warofcontinents.online/auth/refresh';
const AUTH_USERDATA_URL = 'https://auth.warofcontinents.online/auth/userdata';

const initialState = {
    loading: false,
    auth_level: 0, //0-logged out, 1-username and password, 2 - address and signature
    useruuid: '',
    permissions: ['PUBLIC'],
    username: '',
    email: '',
    address: '',
    continent: undefined, 
    errorMessage: ''
}



export const userloginByUsername = createAsyncThunk(
    'auth/userlogin',
    async ({ username, password }) => {
        debug && console.log('userloginByUsername:: params: username', username, 'password', password);
        const response = await axios.post(AUTH_LOGIN_URL, { username, password }, { withCredentials: true });
        debug && console.log('userloginByUsername:: response', response)
        const token = Cookies.get('accessToken');
        debug && console.log('userloginByUsername:: token', token);
        const decoded = await getCurrentUserDataFromToken(token);
        const useruuid = decoded.useruuid;
        debug && console.log('userloginByUsername:: getCurrentUserData() ->', useruuid);
        const userdataquery = AUTH_USERDATA_URL+"?useruuid="+useruuid
        debug && console.log('userloginByUsername:: userdataquery ->', userdataquery);
        const userdata = await axios.get(userdataquery);
        debug && console.log('userloginByUsername:: userdata', userdata);
        return userdata;
    }
)


export const userrefresh = createAsyncThunk(
    'auth/userrefresh',
    async (arg, thunkAPI) => {
        console.log('auth/userrefresh::');
        const response = await axios.post(AUTH_REFRESH_URL, {}, { withCredentials: true });
        debug && console.log('auth/userrefresh:: response', response.data.accessToken);
        const decoded = await getCurrentUserDataFromToken(response.data.accessToken);
        debug && console.log('auth/userrefresh:: decoded', decoded);
        if (!decoded) {
            return thunkAPI.rejectWithValue('No token');
        }
        const useruuid = decoded.useruuid;
        debug && console.log('auth/userrefresh:: getCurrentUserData() ->', useruuid);
        const userdataquery = AUTH_USERDATA_URL+"?useruuid="+useruuid
        debug && console.log('auth/userrefresh:: userdataquery ->', userdataquery);
        const userdata = await axios.get(userdataquery);
        debug && console.log('auth/userrefresh:: userdata', userdata);
        return userdata;
    }
)

export const userlogout = createAsyncThunk(
    'auth/userlogout',
    async () => {
        debug && console.log('auth/userlogout::');
        const response = await axios.post(AUTH_LOGOUT_URL, {}, { withCredentials: true });
        debug && console.log('auth/userlogout:: response', response);
        return response;
    }
)

export const authSlice = createSlice({
    name: 'auth',
    initialState,

    extraReducers: (builder) => {
        builder.addCase(userloginByUsername.pending, (state, action) => {
                state.loading = true
                state.errorMessage = ''
        });
        builder.addCase(userloginByUsername.fulfilled, (state, action) => {
            debug && console.log('in the userlogin reducer with ', action.payload)
            const userdata = action.payload.data
            state.loading = false
            state.auth_level = 1
            state.useruuid = userdata.useruuid
            state.permissions = userdata.permissions
            state.username = userdata.username
            state.email = userdata.email
            state.address = userdata.web3_address
            state.continent = 0

            //email_verified
            //address_verified
            //user_type
        });
        builder.addCase(userloginByUsername.rejected, (state, action) => {
            debug && console.log('userloginByUsername.rejected:: ', action.error);
            state.loading = false
            state.auth_level = 0
            state.useruuid = ''
            state.permissions = ['PUBLIC']
            state.username = ''
            state.email = ''
            state.address = ''
            state.continent = undefined
            state.error = action.error.message;
        });

        builder.addCase(userrefresh.pending, (state, action) => {
            state.loading = true
            state.errorMessage = ''
        });
        builder.addCase(userrefresh.fulfilled, (state, action) => {
            debug && console.log('in the userrefresh reducer with ', action.payload)
            const userdata = action.payload.data
            state.loading = false
            state.auth_level = 1
            state.useruuid = userdata.useruuid
            state.permissions = userdata.permissions
            state.username = userdata.username
            state.email = userdata.email
            state.address = userdata.web3_address
            state.continent = 0

        });

        builder.addCase(userrefresh.rejected, (state, action) => {
            debug && console.log('userrefresh.rejected:: ', action.error);
            state.loading = false
            state.auth_level = 0
            state.useruuid = ''
            state.permissions = ['PUBLIC']
            state.username = ''
            state.email = ''
            state.address = ''
            state.continent = undefined
            state.error = action.error.message;
        });


        builder.addCase(userlogout.pending, (state, action) => {
            state.loading = true
            state.errorMessage = ''
        });
        builder.addCase(userlogout.fulfilled, (state, action) => {
            debug && console.log('userlogout.fulfilled:: ', action.payload);
            state.loading = false
            state.auth_level = 0
            state.useruuid = ''
            state.permissions = ['PUBLIC']
            state.username = ''
            state.email = ''
            state.address = ''
            state.continent = undefined
            state.errorMessage = ''
        });
        builder.addCase(userlogout.rejected, (state, action) => {
            debug && console.log('userlogout.rejected:: ', action.error);
            state.loading = false;
            state.error = action.error.message;
        });

    }

})

export const {loginfailure} = authSlice.actions;

export default authSlice.reducer