import LoginPage from "../features/LoginPage/LoginPage"
import Messages from "../features/Messages/Messages"
import LeaderBoard from "../features/LeaderBoard/LeaderBoard"
import Exchange  from "../features/Exchange/Exchange"
import Lands from "../features/Lands/Lands"
import Land from "../features/Lands/Land"
import Heroes from "../features/Heroes/Heroes"
import Balances from "../features/Balances/Balances"

export const defaultRoutes = [
    {path: '/heroes', element: <Heroes/>, exact: true, permission: 'EPOCH1'},
    {path: '/lands', element: <Lands/>, exact: true, permission: 'EPOCH1'},
    {path: '/land/:landid', element: <Land/>, exact: true, permission: 'EPOCH1'},
    {path: '/exchange/start', element: <Exchange/>, exact: true, permission: 'PUBLIC'},
    {path: '/account/login', element: <LoginPage/>, exact: true, permission: 'PUBLIC'},
    {path: '/messages/default', element: <Messages/>, exact: true, permission: 'LOGGEDIN'},
    {path: '/leaderboard', element:  <LeaderBoard/>, exact: false, permission: 'EPOCH1'},
    {path: '/balances', element:  <Balances/>, exact: false, permission: 'EPOCH1'},
    {path: '*', element:  <LoginPage/>, exact: false, permission: 'PUBLIC'}
]