import {useState, useEffect} from 'react'
import { Stack, Typography, Button, Link } from '@mui/material'
import img from '../../assets/img/forms/StockCake-Stacked Coin Towers_1723624750.jpg'
import WOCPAD from '../../utils/blockchain/WOCPAD';
import CoinInput from './components/CoinInput';
const { ethereum } = window;

async function addCoinToMM(tokenAddress, tokenSymbol, tokenDecimals) {
  
  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    await ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20', // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
        },
      },
    });
  } catch (error) {
    console.log(error);
  }
};

const addPTCToMM = () => {
  addCoinToMM('0x5826D7e5334d1c92AF4E233C4547247b2A4Ca255', 'WOCPTC', 18);
}

const addUSDToMM = () => {
  addCoinToMM('0xD816FDfb1F6B9633177E20aFcBE5DBA31A68185D', 'TSTUSD', 18);
}

const ExchPlatinum = () => {
  const [coinFromSymbol, setCoinFromSymbol] = useState('');
  const [coinToSymbol, setToFromSymbol] = useState('');
  const [coinFromBalance, setCoinFromBalance] = useState(0);
  const [coinToBalance, setCoinToBalance] = useState(0);
  const [exchangeRate, setExchangeRate] = useState(0);

  useEffect(() => {
    async function fetchData() {
            setCoinFromSymbol(await WOCPAD.getCoinFromSymbol());
            setToFromSymbol(await WOCPAD.getCoinToSymbol());
            const bigCoinFromBalance = Number(await WOCPAD.fromCoinBalance())
            setCoinFromBalance(parseFloat(bigCoinFromBalance/10**18));
            const bigCoinToBalance = Number(await WOCPAD.toCoinBalance())
            setCoinToBalance(parseFloat(bigCoinToBalance/10**18));
          }
    fetchData()
    console.log(coinFromSymbol, coinFromBalance, coinToSymbol, coinToBalance)
    }, []);

  function calcRate(from, to) {
    if(Number(from) == 0 || Number(to) == 0) return 0;
    return parseFloat(Number(from) / Number(to)).toFixed(4);
  }
  
  return (
    <Stack spacing={2} direction='row' justifyContent='space-between'>
      <Stack sx={{ flex: 1}}>
        <Typography variant='body1'>You can easily get involved in testing the 
        platinum coin purchase function in a few simple steps:</Typography>
        <Typography variant='body1'>1. <Link href="https://chainlist.org/chain/421614" target='_blank'>add test Arbitrum Sepolia</Link> to your MetaMask</Typography> 
        <Typography variant='body1'>2. <Link href="https://www.l2faucet.com/arbitrum" target='_blank'>get free</Link> ETH to pay for gas</Typography> 
        <Typography variant='body1'>3. add tokens <Button onClick={addUSDToMM} style={{ width: 'auto'}}>TSTUSD</Button> and <Button onClick={addPTCToMM} style={{ width: 'auto'}}>WOCPTC</Button> to your MM</Typography> 
        <Typography variant='body1'> 4. get the required amount of token TSTUSD <Link href="https://discord.gg/qCbvdMnh" target="_blank">through our discord</Link> </Typography> 
        <Typography variant='body1'>5. purchase token WOCPTC on the launchpad</Typography> 
      </Stack>
      <Stack direction='column' sx={{ flex: 1}}>
        <CoinInput 
            id="CoinFromInput"
            symbol={coinFromSymbol}
            balance={coinFromBalance}
            onInput={async (event) => {
                document.getElementById("CoinToInput").value=
                    await WOCPAD.getCoinToAdopted(
                    document.getElementById("CoinFromInput").value
                );
                setExchangeRate(calcRate(document.getElementById("CoinFromInput").value, document.getElementById("CoinToInput").value))
            }}
        />
        <CoinInput 
          id="CoinToInput"
          symbol={coinToSymbol}
          balance={coinToBalance}
          onInput={async (event) => {
              document.getElementById("CoinFromInput").value=
                  await WOCPAD.getCoinFromAdopted(
                  document.getElementById("CoinToInput").value
              );
              setExchangeRate(calcRate(document.getElementById("CoinFromInput").value, document.getElementById("CoinToInput").value))
          }}
      />
      <Button variant="outlined" onClick={async ()=>{
          // setWaitForTransaction(true)
          const ret = await WOCPAD.fromCoinApproveAdopted(document.getElementById("CoinFromInput").value);
          if (ret) {
              const ret2 = await WOCPAD.sellAdopted(document.getElementById("CoinToInput").value);
              if (ret2) {
                  setCoinFromBalance(parseFloat(coinFromBalance) - parseFloat(document.getElementById("CoinFromInput").value));
                  setCoinToBalance(parseFloat(coinToBalance) + parseFloat(document.getElementById("CoinToInput").value));
              }
              document.getElementById("CoinFromInput").value="";
              document.getElementById("CoinToInput").value="";
          }
          // setWaitForTransaction(false);

      }}>Buy PTC</Button>           

      </Stack>
      <Stack sx={{ flex: 1}}> <img src={img} alt="platinum coins" height={350}/></Stack>
    </Stack>
  )
}

export default ExchPlatinum