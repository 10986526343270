import ColoredRarityText from '../../../components/nft/ColoredRarityText'
import HeroImageAndElement from './HeroImageAndElement'

const HeroCardSimple = ({hero}) => {
    return (
        <div style = {{margin:'5px'}}>
            <ColoredRarityText rarity={hero.rarity} text={`${hero.name} #${hero.heroid}`}/>
            <HeroImageAndElement hero={hero}/>
            <ColoredRarityText rarity={hero.rarity} text={hero.busy?
                new Date(hero.estimated_time).toLocaleString('en-EN', {day: '2-digit',  month: '2-digit', year: 'numeric',  hour: '2-digit',  minute: '2-digit',  second: '2-digit', hour12: false})
                :'free'}/>
        </div>
  )
}

export default HeroCardSimple