import {useState} from 'react'
import {Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Snackbar
} from '@mui/material';
import {useQuestLogQuery} from '../../redux/api/musabikApi'
import {useQuestLogClaimMutation} from '../../redux/api/musabikApi'
import StartClaimQuestMission from '../StartMission/StartClaimQuestMission'

const debug = true;

const ClaimQuests = () => {
    const [claimQuestLog] = useQuestLogClaimMutation()
    const [openClaimQuestRewardMission, setOpenClaimQuestRewardMissio] = useState(false)
    const [selectedQuestLogId, setSelectedQuestLogId] = useState(null)

    const { data=[], isLoading } = useQuestLogQuery()
    if (isLoading) return 'Loading...'
  
    return (
      <>
        <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Quest</TableCell>
              <TableCell align="right">Ended By</TableCell>
              <TableCell align="right">Claim</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {
              data?.slice(0, 10).map((log) => {
                if (!log.achieved) return null;
                return (
                    <TableRow key={log.questlogid}>
                      <TableCell component="th" scope="row">
                        {log.description}
                      </TableCell>
                      <TableCell align="right">{log.end_date.split('T')[0]}</TableCell>
                      <TableCell align="right">
                        <Button variant="contained" color="secondary" disabled={log.claimed}
                             onClick={(e) => {
                              e.stopPropagation(); // Предотвращает срабатывание клика по строке
                              setSelectedQuestLogId(log.questlogid);
                              setOpenClaimQuestRewardMissio(true);
                            }}>Claim</Button>
                      </TableCell>
                    </TableRow>
                  )

              })
            }
          </TableBody>
        </Table>
        </TableContainer>
        <StartClaimQuestMission open={openClaimQuestRewardMission} setOpen={setOpenClaimQuestRewardMissio} questlogid={selectedQuestLogId}/>

    </>
      )
}

export default ClaimQuests