import Cookies from 'js-cookie';
const debug = false;

const base64UrlDecode = (str) => {
    const base64 = str.replace(/-/g, '+').replace(/_/g, '/');
    const padding = '='.repeat((4 - base64.length % 4) % 4);
    const base64WithPadding = base64 + padding;
    const decoded = atob(base64WithPadding);
  
    return decoded;
  };
  
  const parseJwt = (token) => {
    const parts = token.split('.');
  
    if (parts.length !== 3) {
      throw new Error('Invalid JWT');
    }
  
    const payload = base64UrlDecode(parts[1]);
    return JSON.parse(payload);
  };

  export const isAccessTokenPresent = () => {
    const token = Cookies.get('accessToken');
    if (!token) {
      return false;
    }
    return (true)
  }

  export const isAccessTokenValid = () => {
    const token = Cookies.get('accessToken');
    if (!token) {
      return false;
    }
    const decoded = parseJwt(token);
    const now = Date.now() / 1000;
    return (decoded.exp > now);
  }


  export const getCurrentUserDataFromToken = (token) => {
    if (!token) {
      throw new Error('No token found');
    }
    const decoded = parseJwt(token);
    const now = Date.now() / 1000;
    if (decoded.exp > now) {
        debug && console.log('getCurrentUserData:: VALID:', decoded, token);
        return decoded;
    } else {
      throw new Error('Token expired');
    }
  }

  
  
