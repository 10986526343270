export const terrainTypes = [
        "Valley",
        "Forest",
        "Mountains",
        "River",
        "Arctic"
];
export const continents = [
        "Alef",
        "Beth"
];

export const rarityTypes = [
    "Common",
    "Uncommon",
    "Rare",
    "Epic",
    "Legendary"
];
export const sexTypes = [
    "Female",
    "Male"
];

export const elementTypes = [
"Air", "Fire", "Water", "Earth"
];


export const rarityColors = [
    "#DCDCDC",
    "#228B22",
    "#4169E1",
    "#8A2BE2",
    "#FFD700"
];