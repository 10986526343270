import {wocheromanagerabi, wocheromanageraddress, wocheroabi, wocheroaddress} from './abi.js';
import Metamasker from "./metamasker.js";
import WOCPTC from './WOCPTC.js';
import bigInt from 'big-integer';
const debug = false;

export default class WOCHEROMANAGER {

    static async getHeroCost() {
        const { Web3 } = require('web3');
        const { ethereum } = window;
        const web3 = new Web3(ethereum);
        const heroManagerInstance = new web3.eth.Contract(wocheromanagerabi, wocheromanageraddress);
        const heroCost = await heroManagerInstance.methods.getHeroCost().call();
        
        return(heroCost);
    }

    static async allowed() {
        const address = await Metamasker.getCurrentAccount();
        if (address) {
            const amount = await WOCPTC.allowance(address, wocheromanageraddress);
            return amount;
        } else {
            return 0;
        }
    }


    static async checkAllowance() {
        const address = await Metamasker.getCurrentAccount();
        return await WOCPTC.allowance(address, wocheromanageraddress);
    }
    static async resetApproval() {
        await WOCPTC.approve(wocheromanageraddress, 0);
    }

    static async requestApproval() {
        const heroPrice = await this.getHeroCost();
        const approved = await this.allowed();
        if (approved < heroPrice) {
            return await WOCPTC.approve(wocheromanageraddress, heroPrice);
        }
        return true;
    }
    static async mint4ptc() {
        const { Web3 } = require('web3');
        const { ethereum } = window;
        const web3 = new Web3(ethereum);
        const heroManagerInstance = new web3.eth.Contract(wocheromanagerabi, wocheromanageraddress);
        const heroInstance = new web3.eth.Contract(wocheroabi, wocheroaddress);
        
        const address = await Metamasker.getCurrentAccount();
        try {
            const mintTrx = await heroManagerInstance.methods
            .mint4ptc(address, 0)
            .send({ from: address });

            debug && console.log('mintTrx', mintTrx);

            const hexHeroId = mintTrx.logs[2].topics[3];
            const newHeroId = Number(bigInt(hexHeroId.slice(2), 16));
            return newHeroId;

            //const trxs = await heroInstance.getPastEvents('HeroMinted');
            //debug && console.log('trxs', trxs);
            //return Number(trxs[0].returnValues.newHeroId);

        } catch (error) {
            console.log(error);
            return false;
        }
    }


    

}





