import React from 'react';
import './CoinInput.css';


const CoinInput = ({symbol, balance, ...props}) => {
    const adaptedBalance = parseFloat(balance).toFixed(4);
    return (
      <div>
          <div>
             <input
                {...props}
                className="coininput"
                inputMode="decimal"
                autoComplete="off"
                autoCorrect="off"
                type="text"
                pattern="^[0-9]*[.,]?[0-9]*$"
                placeholder="0.0"
                minLength="1"
                maxLength="10"
                spellCheck="false"
              /> 
          </div>
          <div style={{marginLeft:'10px'}}>
            {symbol} {adaptedBalance}
          </div>
      </div>
      
    );
};

export default CoinInput;