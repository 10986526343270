import { resApi } from './resApi';

export const musabikApi = resApi.injectEndpoints({
    overrideExisting: true,
    endpoints: (builder) => ({
        medalsNow: builder.query({
            query: () => ({
                url:`/musabik/medals/cookie`,
                method: 'GET',
                })
        }),
        questLog: builder.query({
            query: () => ({
                url:`/musabik/quest/log/cookie`,
                method: 'GET',
                })
        }),
        questLogClaim: builder.mutation({
            query: (questlogid) => ({
                url:`/musabik/quest/log/claim/cookie`,
                method: 'POST',
                body:{questlogid},
            })
        }),
    }),
});

export const {useMedalsNowQuery, useQuestLogQuery, useQuestLogClaimMutation} = musabikApi;