import {useState} from 'react'
import {
    Stack,
    Button,
    Box,
    Typography,
    Tooltip,
    Link
} from '@mui/material'
import LandCard from './components/LandCard'
import StartHeroTransferMission from '../StartMission/StartHeroTransferMission'
import StartClaimGoldMission from '../StartMission/StartClaimGoldMission'
import img from '../../assets/img/forms/land_gold_storage.png'
import {useCoinBalanceQuery} from '../../redux/api/lakanApi'
import {useToharvestQuery} from '../../redux/api/landApi'

const debug = true;


const LandGold = ({land}) => {
    const [openHeroTransferMission, setOpenHeroTransferMission] = useState(false)
    const [openClaimGoldMission, setOpenClaimGoldMission] = useState(false)
    // const handleTransferMissionClose = () => {setOpenHeroTransferMission(false)}
    // const {data: coinBalance} = useCoinBalanceQuery()
    const {data: toHarvest} = useToharvestQuery(land.landid, {
        skip: !land?.landid, 
        pollingInterval: 200000,
      })
    // if(!coinBalance || !toHarvest) return 'Loading...'
    if(!toHarvest) return 'Loading...'
    debug && console.log('toHarvest', toHarvest)
    // const asset = coinBalance.find(item => item.asset_code === '1000299') ?? 0;
  return (
    <>
    <Stack spacing={10} direction='row'>
        <Box sx={{ 
                flex: 0, 
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                }}> 
            <LandCard land={land} />
            <Button 
                variant='outlined' sx={{ width: 200 }}
                onClick={() => setOpenHeroTransferMission(true)}
                >Transfer hero</Button>
        </Box>
        <Box sx={{ 
                flex: 1, 
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                }}> 

            
            <Tooltip title={<>And, you can claim a lot from this Land. For more details follow <Link href="https://warofcontinents.online/docs/first-steps/my_lands_gold" target="_blank">the white rabbit</Link></>} placement='top'>
                <Typography variant='h5'>Gold to Claim</Typography>
            </Tooltip>
                <Typography variant='h4' color='secondary'>{Math.round(toHarvest.goldToClaim)}</Typography>

        </Box>
        <Box  sx={{ 
                flex: 1, 
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                }}>
          <Button variant='outlined' onClick={() => setOpenClaimGoldMission(true)} sx={{ width: 250 }}>Claim your Gold!</Button>
       </Box>
        <Box sx={{ flex: 1 }}>
            <img src={img} height={300} width={300} style={{borderRadius:'10px', border:'1px solid', 'borderColor':'gray'}} alt='gold storage' />
        </Box>
    </Stack>
    <StartHeroTransferMission open={openHeroTransferMission} setOpen={setOpenHeroTransferMission} landid={land.landid}/>
    <StartClaimGoldMission open={openClaimGoldMission} setOpen={setOpenClaimGoldMission} landid={land.landid}/>
    </>
  )
}


export default LandGold