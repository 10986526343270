import {woclandmanagerabi, woclandmanageraddress, woclandabi, woclandaddress} from './abi.js';
import Metamasker from "./metamasker.js";
import WOCPTC from './WOCPTC.js';
import bigInt from 'big-integer';
const debug = true;

export default class WOCLANDMANAGER {

    static async getLandCost() {
        const { Web3 } = require('web3');
        const { ethereum } = window;
        const web3 = new Web3(ethereum);
        const landManagerInstance = new web3.eth.Contract(woclandmanagerabi, woclandmanageraddress);
        const landCost = await landManagerInstance.methods.getLandCost().call();
        
        return(landCost);
    }

    static async allowed() {
        const address = await Metamasker.getCurrentAccount();
        if (address) {
            const amount = await WOCPTC.allowance(address, woclandmanageraddress);
            return amount;
        } else {
            return 0;
        }
    }


    static async checkAllowance() {
        const address = await Metamasker.getCurrentAccount();
        return await WOCPTC.allowance(address, woclandmanageraddress);
    }
    static async resetApproval() {
        await WOCPTC.approve(woclandmanageraddress, 0);
    }

    static async requestApproval() {
        const landPrice = await this.getLandCost();
        const approved = await this.allowed();
        if (approved < landPrice) {
            return await WOCPTC.approve(woclandmanageraddress, landPrice);
        }
        return true;
    }
    static async mint4ptc() {
        const { Web3 } = require('web3');
        const { ethereum } = window;
        const web3 = new Web3(ethereum);
        const landManagerInstance = new web3.eth.Contract(woclandmanagerabi, woclandmanageraddress);
        const landInstance = new web3.eth.Contract(woclandabi, woclandaddress);
        
        const address = await Metamasker.getCurrentAccount();
        try {
            const mintTrx = await landManagerInstance.methods
            .mint4ptc(address, 0)
            .send({ from: address });

            debug && console.log('minttrx', mintTrx);

            const hexLandId = mintTrx.logs[2].topics[3];
            const newLandId = Number(bigInt(hexLandId.slice(2), 16));
            return newLandId;
            
            // const trxs = await landInstance.getPastEvents('LandMinted');
            // debug && console.log('trxs', trxs);
            // return Number(trxs[0].returnValues.newLandId);

        } catch (error) {
            console.log(error);
            return false;
        }
    }
}



