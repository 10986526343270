import { Box, Typography, Stack } from '@mui/material';
import { keyframes } from '@emotion/react';

import h0f from '../../../assets/img/heroes/woc-hero-0-female-common-500x875.png'
import h0m from '../../../assets/img/heroes/woc-hero-0-male-common-500x875.png'
import h1f from '../../../assets/img/heroes/woc-hero-1-female-uncommon-500x875.png'
import h1m from '../../../assets/img/heroes/woc-hero-1-male-uncommon-500x875.png'
import h2f from '../../../assets/img/heroes/woc-hero-2-female-rare-500x875.png'
import h2m from '../../../assets/img/heroes/woc-hero-2-male-rare-500x875.png'
import h3f from '../../../assets/img/heroes/woc-hero-3-female-epic-500x875.png'
import h3m from '../../../assets/img/heroes/woc-hero-3-male-epic-500x875.png'
import h4f from '../../../assets/img/heroes/woc-hero-4-female-legendary-500x875.png'
import h4m from '../../../assets/img/heroes/woc-hero-4-male-legendary-500x875.png'


const animationDuration = 2;
const cycleAnimation = keyframes`
  0% { background-image: url(${h0f}); }
  10% { background-image: url(${h0m}); }
  20% { background-image: url(${h1f}); }
  30% { background-image: url(${h1m}); }
  40% { background-image: url(${h2f}); }
  50% { background-image: url(${h2m}); }
  60% { background-image: url(${h3f}); }
  70% { background-image: url(${h3m}); }
  80% { background-image: url(${h4f}); }
  90% { background-image: url(${h4m}); }
  100% { background-image: url(${h0f}); }
`;


const HeroImageCycle = () => {
  return (
    <Stack spacing={2} direction='column' alignItems="center" justifyContent="center">
    <Box
      sx={{
        width: '200px', // ширина контейнера
        height: '350px', // высота контейнера
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        animation: `${cycleAnimation} ${animationDuration}s linear infinite`,
        borderRadius: '10px'
      }}
    />
    <Typography variant='h4'>
            #???
    </Typography>
    </Stack>
  )
}

export default HeroImageCycle