import {useState} from 'react'

import {
    Paper,
    Tabs,
    Tab,
    Tooltip,
    Box,
    Typography,
} from '@mui/material'


import ExchPlatinum from './ExchPlatinum';
import ExchLand from './ExchLand';
import ExchHero from './ExchHero';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


 const Exchange = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };


  return (
    <Paper sx={{ maxWidth: '80%', margin: '40px auto 0 auto', 
    justifyContent: 'center', height: '500px', }}>
        <Tabs value={value} onChange={handleChange} aria-label="exchange form tabs">
            <Tab label="Buy Platinum" {...a11yProps(0)} />
            <Tab label="Mint Hero" {...a11yProps(1)} />
            <Tab label="Mint Land" {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={value} index={0}>
            <ExchPlatinum />
        </TabPanel>
        <TabPanel value={value} index={1}>
            <ExchHero />
        </TabPanel>
        <TabPanel value={value} index={2}>
            <ExchLand />
        </TabPanel>
    </Paper>
  )
}

export default Exchange