import {useState} from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
IconButton} from '@mui/material';
  import DeleteIcon from '@mui/icons-material/Delete';

import {useGetMessagesQuery, useDeleteMessageMutation, useReadMessageMutation} from '../../redux/api/messagesApi';
const debug = true

const Messages = () => {
  debug && console.log('Messages component render');
  const {data=[], refetch, isLoading} = useGetMessagesQuery();
  debug && console.log('useGetMessagesQuery().data:', data);
  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [readMessage] = useReadMessageMutation();
  const [deleteMessage] = useDeleteMessageMutation();
  if(isLoading) return <div>Loading...</div>

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  
  const handleRowClick = async (row) => {
    debug && console.log('Messages::handleRowClick row:', row);
    setSelectedRow(row);
    setOpen(true);
    if(!row.read){
      await readMessage(row.msguuid);  
    }

  }

  const handleClose = () => {
    refetch()
    setOpen(false);
    setSelectedRow(null);
  };

  const handleDelete = async (msguuid) => {
    console.log('before delete', msguuid);
    
    await deleteMessage(msguuid);  // Вызов API для удаления записи
    refetch()

  };

  return (
  <>
    <Paper sx={{ maxWidth: '70%', margin: '40px auto 0 auto' }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>From</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell >Date</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.messages.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.msguuid} sx={{ height: '40px',fontWeight: row.read ? 'normal' : 'bold', cursor: 'pointer' }}
                onClick={() => handleRowClick(row)}>
                  <TableCell sx={{'color': row.read ? 'primary.main' : 'secondary.main' }}>{row.user1name}</TableCell>
                  <TableCell sx={{'color': row.read ? 'primary.main' : 'secondary.main' }}>{row.subject}</TableCell>
                  <TableCell sx={{'color': row.read ? 'primary.main' : 'secondary.main' }}>{new Date(row.created).toLocaleString('en-EN', {day: '2-digit',  month: '2-digit', year: 'numeric',  hour: '2-digit',  minute: '2-digit',  second: '2-digit'})} </TableCell>
                  <TableCell>
                    {row.deleted ? 'Deleted' : 
                      <IconButton size='small'
                        aria-label="delete"
                        onClick={(e) => {
                          e.stopPropagation(); // Предотвращает срабатывание клика по строке
                          handleDelete(row.msguuid);
                        }}>
                        <DeleteIcon />
                      </IconButton>
  }
                    </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data?.messages?.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10]}
      />
    </Paper>

    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Message Details</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-from">
              {selectedRow ? <><strong>From:</strong> {selectedRow.user1name}</> : ''}
            </DialogContentText>
            <DialogContentText id="alert-dialog-date">
              {selectedRow ? <><strong>Date:</strong> {new Date(selectedRow.created).toLocaleString('en-EN', {day: '2-digit',  month: '2-digit', year: 'numeric',  hour: '2-digit',  minute: '2-digit',  second: '2-digit'})}</> : ''}
            </DialogContentText>
            <DialogContentText id="alert-dialog-subject">
              {selectedRow ? <><strong>Subject:</strong> {selectedRow.subject}</> : ''}
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
              {selectedRow ? <><strong>Body:</strong> {selectedRow.body}</> : ''}
            </DialogContentText>        
          </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>

        </DialogActions>
      </Dialog>

  </>
  );
}

export default Messages