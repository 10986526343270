import { useNavigate } from 'react-router-dom';
import {
  Paper,
  Stack,
  TablePagination,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import {useState} from 'react'
import {useLandsQuery} from '../../redux/api/landApi'
import LandCardSimple from './components/LandCardSimple';
const debug = true;


const Lands = () => {
  const { data=[], isLoading } = useLandsQuery()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const navigate = useNavigate();

  if (isLoading) return 'Loading...'

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
};

const handleLandClick = (land) => {
  navigate(`/land/${land.landid}`);
}


  return (
    <>
      <Paper  sx={{ maxWidth: '60%', margin: '20px auto 0 auto', paddingTop:'10px' }}>
        <Stack direction="row" sx={{ flexWrap: 'wrap', justifyContent: 'center' }}>
                {
                    data?.lands.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((land) => (
                            <Box key={land.landid} onClick={()=>handleLandClick(land)}>
                                <LandCardSimple land={land}/>
                            </Box>
                    ))
                }
        </Stack>
        <TablePagination
            component="div"
            count={data?.lands?.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10]}
      />
      </Paper>
    </>
  )
}

export default Lands