import {rarityColors} from '../../assets/consts/land_and_hero_attributes'

const ColoredRarityText = ({rarity, text}) => {
    return (
        <div style={{
            backgroundColor: rarityColors[rarity], 
            color:'black', 
            font:'bold', 
            borderRadius: '5px', 
            display:'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '3px',
            }}>
            {text} 
        </div> 
    )
}

export default ColoredRarityText