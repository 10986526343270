import React, {useState} from 'react';
import {
  Box,
  TablePagination,
} from '@mui/material';
import {useHeroesOnlandQuery} from '../../../redux/api/heroApi'
import HeroButton from './HeroButton';

const debug=true;

const HeroesOnlandButtonsList = ({landid, setSelectedHero, noHeroMsg}) => {
  const { data=[], isLoading, isSuccess } = useHeroesOnlandQuery(landid)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  
  if (isLoading)  {
    return (<div>Loading...</div>)
  }

  if(data.heroes.length === 0) {
    return (<div>{noHeroMsg}</div>)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
};
  debug && console.log('HeroesButtonsList: data:', data.heroes)
  
  return (<>
    <Box  sx={{
      display: 'flex', // Включаем Flexbox
      flexDirection: 'column', // Устанавливаем вертикальное расположение элементов
      // alignItems: 'flex-start', // Выравниваем элементы по левому краю (опционально)
    }}>
      { 
        data.heroes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((hero) => {
          return (
            <HeroButton key={hero.heroid} hero={hero} setSelectedHero={setSelectedHero} />
          ) 
        })
      }

    </Box>
    <TablePagination
            component="div"
            count={data.heroes.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10]}
      />
    </> )
}

export default HeroesOnlandButtonsList