import LandImage from "./LandImage"
const {elementImg} = require('../../../assets/img/elements')

const LandImageAndElement = ({land}) => {
    return (
        <div style={{
            position: 'relative',
            display: 'inline-block'
        }}>
            <LandImage terrain_type={land.terrain_type} />
            <img src={elementImg[land.element]} style={{
                position: 'absolute',
                top: 10,
                right: 10,
                width: '50px',
                height: '50px',
                backgroundColor: 'white',
                borderRadius: '10px'
                // objectFit: 'cover',
                // mixBlendMode: 'multiply',
                // opacity: 0.5
            }}
            />
        </div>
    )
}

export default LandImageAndElement