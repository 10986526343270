import React from 'react';
import {Route, Routes } from "react-router-dom";
import {defaultRoutes} from './router.js';
import {useSelector} from 'react-redux'

const debug = false;

const AppRouter = () => {
    debug && console.log('AppRouter:: just loaded');
    const permissions = useSelector(state => state.auth.permissions);
    debug && console.log('AppRouter:: permissions', permissions);
    const routes = defaultRoutes.filter(route => permissions.includes(route.permission));
    debug && console.log("AppRouter:: routes", routes);

    return (

        <Routes>
        {
        routes.map(route =>
            <Route 
                element={route.element} 
                path={route.path}
                key={route.path} 
                exact={route.exact}
                />
            )
        }
        </Routes>
    );
};

export default AppRouter;