import {useState} from 'react'
import {
  Paper,
  Tabs,
  Tab,
  Tooltip,
  Box,
  Link
} from '@mui/material'

import UserBalance from './UserBalance';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Balances = () => {

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper sx={{ maxWidth: '100%', margin: '20px auto 0 auto', 
      justifyContent: 'center', height: '500px', }}>
        <Tabs value={value} onChange={handleChange} >
            <Tab label="Coins" {...a11yProps(0)} style={{ minWidth: 80, maxWidth: 250 }} />
            {/* <Tab label="claim quests" {...a11yProps(1)} style={{ minWidth: 80, maxWidth: 250 }} /> */}
        </Tabs>
        <TabPanel value={value} index={0}>
          <UserBalance />
        </TabPanel>
        {/* <TabPanel value={value} index={1}>
          <ClaimQuests />
        </TabPanel> */}
    </Paper>
  )
}

export default Balances