const {heroImages} = require('../../../assets/img/heroes')

const HeroImage = ({sex, rarity}) => {
    if(sex<0 || sex>1 || rarity <0 || rarity>4) return null
    return (
        <img src={heroImages[sex][rarity]} style={{
            width: '200px',
            height: '350px',
            borderRadius: '9px'
          }}  alt="Hero card image" loading="lazy"/>
    )
}

export default HeroImage