import {useState, useEffect} from 'react'
import { Stack, Typography, Button } from '@mui/material'
import WOCPAD from '../../utils/blockchain/WOCPAD';
import WOCHEROMANAGER from '../../utils/blockchain/WOCHEROMANAGER';
import img from '../../assets/img/forms/warior_river_forest_1024x1792.png'
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import HeroImageCycle from './components/HeroImageCycle'
import {useNftHeroQuery} from '../../redux/api/heroApi'
import {heroImages} from '../../assets/img/heroes'
const debug = true;

 const ExchHero = () => {

  const [coinSymbol, setCoinSymbol] = useState('');
  const [coinBalance, setCoinBalance] = useState(0);
  const [heroCost, setHeroCost] = useState(0);

  const [modalVisible, setModalVisible] = useState(false);
  const [mintedHeroId, setMintedHeroId] = useState(undefined)

  const { data, refetch, isFetching, isLoading  } = 
    useNftHeroQuery(mintedHeroId, {
      skip: !mintedHeroId, 
      pollingInterval: 1000,
    });



  useEffect(() => {
    async function fetchData() {
      setCoinSymbol(await WOCPAD.getCoinToSymbol());
      const bigCoinToBalance = Number(await WOCPAD.toCoinBalance())
      setCoinBalance(parseFloat(bigCoinToBalance/10**18));
      setHeroCost(Number(await WOCHEROMANAGER.getHeroCost())/10**18);   
    }
    fetchData()
  }, []);
  

  const handleClose = () => {
    setModalVisible(false);
    setMintedHeroId(undefined);
  };

  return (
    <>
    <Stack spacing={2} direction='row' justifyContent='space-between'>
      <Stack direction='column' sx={{ flex: 1}}>
        <Typography variant='h6'>You can easily get involved in testing 
        Mint Hero</Typography>
        <Typography>
          1. You should have at least {heroCost} {coinSymbol}. <br/>
          If you do not have enough you could go buy Platinum page
        </Typography>
        <Typography>
          2. Just click MINT button. You will be asked for approval and then for mint itself.
        </Typography>
      </Stack>
      <Stack direction='column' alignItems="center" justifyContent="center" sx={{ flex: 1}}>
          <Button 
              variant="contained" 
              color="secondary" 
              onClick={() => {
                async function mintHero() {
                  const ret = await WOCHEROMANAGER.requestApproval();
                  if (ret) {
                    setModalVisible(true);
                    const heroId = await WOCHEROMANAGER.mint4ptc();
                    setMintedHeroId(heroId)
                    setCoinBalance(coinBalance-heroCost);
                    debug && console.log('mintedHeroId', heroId)
                  }
                }
                // setModalVisible(true);
                // const timer = setTimeout(() => { setMintedHeroId(9)}, 5000);
                mintHero()
              }}
          >Mint Hero</Button>
          <div style={{marginLeft:'10px'}}>
              {coinSymbol} {Number(coinBalance).toFixed(2)}
          </div>
      </Stack>
      <Stack sx={{ flex: 1}}>
        <img src={img} alt='hero' width={200} height={350}/>
      </Stack>
    </Stack>
    
    <Dialog open={modalVisible} onClose={handleClose}>
        <DialogTitle>Minting Hero</DialogTitle>
        <DialogContent>
          <Stack spacing={2} direction='column' alignItems="center" justifyContent="center">
            {
              mintedHeroId > 0 && data ?
              <>
              <Typography>
                <img src={heroImages[data.sex][data.rarity]} alt='hero' width={200} height={350}  borderRadius='10px'
                />
              </Typography>
              <Typography variant='h4'>
                #{mintedHeroId}
              </Typography>
              </>
               :
              <HeroImageCycle/>
            }
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ExchHero