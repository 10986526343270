import { resApi } from './resApi';

export const landApi = resApi.injectEndpoints({
    overrideExisting: true,
    endpoints: (builder) => ({
        nftLand: builder.query({
            query: (landid) => `/khalid/nft/land/${landid}`,
        }),
        lands: builder.query({
            query: () => `/khalid/nft/lands/cookie`,
        }),
        vault: builder.query({
            query: (landid) => `/khalid/vault/volume/cookie/${landid}`,
        }),
        seals: builder.query({
            query: (landid) => `/khalid/vault/seals/cookie/${landid}`,
        }),
        elements: builder.query({
            query: (landid) => `/khalid/elements/level/cookie/${landid}`,
        }),
        toharvest: builder.query({
            query: (landid) => `/khalid/gold/toharvest/cookie/${landid}`,
        }),

// вторая версия через прокси
        inventory: builder.query({
            query: (landid) => `/khalid/land/inventory/cookie/landid/${landid}`,
        }),
    }),
});

export const {useNftLandQuery, useLandsQuery, useVaultQuery, useSealsQuery, useElementsQuery, useToharvestQuery,
    useInventoryQuery
} = landApi;