import {useState} from 'react'
import {
    Stack,
    Button,
    Box,
    Typography,
    Tooltip,
    Link
} from '@mui/material'
import LandCard from './components/LandCard'
import StartHeroTransferMission from '../StartMission/StartHeroTransferMission'
import StartUpgradeSealMission from '../StartMission/StartUpgradeSealMission'
import img from '../../assets/img/forms/land_elements_seal.png'
import { elementImg } from '../../assets/img/elements'
import {elementTypes} from '../../assets/consts/land_and_hero_attributes'
import {useSealsQuery} from '../../redux/api/landApi'

const debug=true;

const LandSeals = ({land}) => {
    const [openHeroTransferMission, setOpenHeroTransferMission] = useState(false)
    const [openUpgradeSealMission, setUpgradeSealMission] = useState(false)
    const [element, setElement] = useState(undefined)

    const {data} = useSealsQuery(land.landid)
    if(!data) return 'Loading...'
    debug && console.log('useSealsQuery: data:', data)

  return (<>
    <Stack spacing={10} direction='row'>
        <Box sx={{ 
                flex: 0, 
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                }}> 
            <LandCard land={land}/>
            <Button variant='outlined' onClick={() => setOpenHeroTransferMission(true)} sx={{ width: 200 }}>Transfer hero</Button>

        </Box>
        <Box sx={{ 
                flex: 1, 
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                }}>
             {
                data.seals.map((item, index) => (
                    <Box sx={{ 
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        }}
                        key={index}
                        >
                        <Tooltip title={<>{elementTypes[index]} Seal decrease leakage speed of your elementals. For more details follow <Link href="https://warofcontinents.online/docs/roadmap/epoch1" target="_blank">the link</Link></>} placement='top'>
                            <img src={elementImg[index]} height={50} with={50} style={{ marginRight: 16 }} alt='element' />
                        </Tooltip>
                        <Typography color='secondary' variant='h4'>{item}</Typography>
                    </Box>
                ))
             }
        </Box>
        <Box  sx={{ 
                flex: 1, 
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                alignItems: 'center',
                }}>
            <Button variant='outlined' onClick={() => {setElement(0); setUpgradeSealMission(true)}} sx={{ width: 250 }}>Upgrade Air Seal</Button>
            <Button variant='outlined' onClick={() => {setElement(1); setUpgradeSealMission(true)}} sx={{ width: 250 }}>Upgrade Fire Seal</Button>
            <Button variant='outlined' onClick={() => {setElement(2); setUpgradeSealMission(true)}} sx={{ width: 250 }}>Upgrade Water Seal</Button>
            <Button variant='outlined' onClick={() => {setElement(3); setUpgradeSealMission(true)}} sx={{ width: 250 }}>Upgrade Earth Seal</Button>
        </Box>
        <Box sx={{ flex: 1 }}>
            <img src={img} height={300} width={300} style={{borderRadius:'10px', border:'1px solid', 'borderColor':'gray'}} alt='gold storage' />
        </Box>
    </Stack>    
    <StartHeroTransferMission open={openHeroTransferMission} setOpen={setOpenHeroTransferMission} landid={land.landid}/>
    <StartUpgradeSealMission open={openUpgradeSealMission} setOpen={setUpgradeSealMission} landid={land.landid} element={element}/>
    </>
  )
}

export default LandSeals