import {useEffect} from 'react';
import { BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { userrefresh } from './redux/slices/authSlice';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';
import AppRouter from './router/AppRouter'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Navbar } from './features/Navbar/Navbar';
import { Footer } from './features/Navbar/Footer';

const darkTheme = createTheme({
        palette: {
          mode: 'dark',
          primary: {
            main: '#cccccc',   //#e85635
          },
          secondary: {
            light: '#faa307',
            main: '#ef5d04',
            dark: '#9d0208',
            contrastText: '#343434'
          },
          background: {
            default: '#343434',  // Фон для темной темы
            paper: '#424242',
          },
        },
      });

const debug = true;

function App() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  useEffect( () => {
    debug && console.log('App:: useEffect:: auth: ', auth);
    if (auth?.auth_level==0) { 
      dispatch(userrefresh());
    } 
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
        <BrowserRouter >
          <div className="App">
            <Navbar/>
            <AppRouter/>
          </div>
        </BrowserRouter>
      <Footer/>
    </ThemeProvider>
  );
}

export default App;
