import { useParams } from 'react-router-dom';
import {useState} from 'react'
import {
    Paper,
    Tabs,
    Tab,
    Tooltip,
    Box,
    Link
} from '@mui/material'
import {useNftLandQuery} from '../../redux/api/landApi'

import LandHeroes from './LandHeroes';  
import LandVault from './LandVault';
import LandSeals from './LandSeals';
import LandElements from './LandElements';
import LandGold from './LandGold';
import LandInventory from './LandInventory';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const Land = () => {
    const { landid } = useParams();
    const [value, setValue] = useState(0);
    const { data } = useNftLandQuery(landid);
    if(!data) return 'Loading...';

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
        <Paper sx={{ maxWidth: '100%', margin: '20px auto 0 auto', 
            justifyContent: 'center', height: '500px', }}>
                <Tabs 
                  value={value} 
                  onChange={handleChange} >
                    <Tooltip title={<>Browse your heroes list</>} placement="bottom">
                        <Tab label="heroes" {...a11yProps(0)} style={{ minWidth: 83, maxWidth: 200 }} />
                    </Tooltip>
                    <Tooltip title={<>Send forth your Hero on a mission and claim the gold that is rightfully yours! For more information follow the <Link target="_blank" href='https://warofcontinents.online/docs/first-steps/my_lands_gold'>wisdom link</Link></>} placement="bottom">
                        <Tab label="gold" {...a11yProps(1)} style={{ minWidth: 83, maxWidth: 200 }} />
                    </Tooltip>
                    <Tooltip title={<>In each Land, there are four Vessels of the Elements, serving as sanctuaries for the Elementals. Through their missions, Heroes gather these precious elemental essences and bring them back for safekeeping, from where you may wield them to elevate the level of the elements. For more information follow the <Link target="_blank" href='https://warofcontinents.online/docs/first-steps/my_lands_vault'>wisdom link</Link></>} placement="bottom">
                        <Tab label="vault" {...a11yProps(2)} style={{ minWidth: 83, maxWidth: 200 }} />
                    </Tooltip>
                    <Tooltip title={<>Elementals, by their very nature, are highly fluid, with few barriers capable of containing them; thus, they gradually seep from their Vessels. However, by enhancing the Seals of the Elements, you can significantly reduce the rate of their escape. For more information follow the <Link target="_blank" href='https://warofcontinents.online/docs/first-steps/my_lands_seals'>wisdom link</Link></>} placement="bottom">
                        <Tab label="seals" {...a11yProps(3)} style={{ minWidth: 83, maxWidth: 200 }} />
                    </Tooltip>
                    <Tooltip title={<>Upgrading the level of each Element in your Land will bestow undeniable advantages upon you and your empire. For instance, as the elements are strengthened, the yield of gold increases. For more information follow the <Link target="_blank" href='https://warofcontinents.online/docs/first-steps/my_lands_elements'>wisdom link</Link></>} placement="bottom">
                        <Tab label="elements" {...a11yProps(4)} style={{ minWidth: 83, maxWidth: 200 }} />
                    </Tooltip>
                    <Tooltip title={<>Inventory</>} placement="bottom">
                        <Tab label="inventory" {...a11yProps(5)} style={{ minWidth: 83, maxWidth: 200 }} />
                    </Tooltip>
                </Tabs>
                <TabPanel value={value} index={0}>
                    <LandHeroes land={ data } />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <LandGold land={ data } />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <LandVault land={ data } />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <LandSeals land={ data } />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <LandElements land={ data } />
                </TabPanel>
                <TabPanel value={value} index={5}>
                    <LandInventory land={ data } />
                </TabPanel>
            </Paper>
    )
}

export default Land