import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import { resApi } from './resApi';

export const messagesApi = resApi.injectEndpoints({
    overrideExisting: true,
    endpoints: (builder) => ({
        getMessages: builder.query({
            query: () => '/riyad/messages/list/cookie',
            providesTags: ['messages'],
        }),
        getUnreadQty: builder.query({
            query: () => 'riyad/messages/unreadqty/cookie',
            providesTags: ['unreadqty'],

        }),
        readMessage: builder.mutation({
            query: (uuid) => ({
                url: `riyad/actions/readmessage/${uuid}`,
                method: 'DELETE',
                invalidatesTags: ['messages', 'unreadqty'],
            }),
        }),
        deleteMessage: builder.mutation({
            query: (uuid) => ({
                url: `riyad/actions/deletemessage/${uuid}`,
                method: 'DELETE',
                invalidatesTags: ['messages', 'unreadqty'],
            }),
        }),
    }),
});

export const {useGetMessagesQuery, useGetUnreadQtyQuery, useDeleteMessageMutation, useReadMessageMutation} = messagesApi;