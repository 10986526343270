import {woclaunchpadabi, woclaunchpadaddress, erc20abi} from './abi.js';
import Metamasker from "./metamasker.js";


export default class WOCPAD {

    static async getCoinFromAddress() {
        const { Web3 } = require('web3');
        const { ethereum } = window;
        const web3 = new Web3(ethereum);

        const wocPadInstance = new web3.eth.Contract(woclaunchpadabi, woclaunchpadaddress);

        const ds = await wocPadInstance.methods.getCoinFromAddress().call();
        return ds;
    }  

    static async getCoinToAddress() {
        const { Web3 } = require('web3');
        const { ethereum } = window;
        const web3 = new Web3(ethereum);

        const wocPadInstance = new web3.eth.Contract(woclaunchpadabi, woclaunchpadaddress);

        const ds = await wocPadInstance.methods.getCoinToAddress().call();
        return ds;
    }

    static async getERC20Symbol(address) {
        const { Web3 } = require('web3');
        const { ethereum } = window;
        const web3 = new Web3(ethereum);

        const coinContract = new web3.eth.Contract(erc20abi, address);

        const symbol = await coinContract.methods.symbol().call();
        return symbol;
    }

    static async getCoinFromSymbol() {
        const address = await this.getCoinFromAddress();
        const symbol = await this.getERC20Symbol(address);
        return (symbol);
    }

    static async getCoinToSymbol() {
        const address = await this.getCoinToAddress();
        const symbol = await this.getERC20Symbol(address);
        return (symbol);
    }

    static async getds() {
        const { Web3 } = require('web3');
        const { ethereum } = window;
        const web3 = new Web3(ethereum);

        const wocPadInstance = new web3.eth.Contract(woclaunchpadabi, woclaunchpadaddress);

        const ds = await wocPadInstance.methods.getds().call();
        return ds;
    }

    static async px(x) {
        const { Web3 } = require('web3');
        const { ethereum } = window;
        const web3 = new Web3(ethereum);

        const wocPadInstance = new web3.eth.Contract(woclaunchpadabi, woclaunchpadaddress);

        const p0 = await wocPadInstance.methods.getp0().call();
        const pmax = await wocPadInstance.methods.getpmax().call();
        const dmax= await wocPadInstance.methods.getdmax().call();

        const ds = await this.getds();
        const px = p0 +((web3.utils.toBigInt(x)+ds)*(pmax-p0)/dmax);
        return px;
}


    static async getCoinFromAmount(x) {
        const { Web3 } = require('web3');
        const { ethereum } = window;
        const web3 = new Web3(ethereum);

        const pxx = (await this.px(x));
        const px0 = (await this.px(0));
        const y = web3.utils.toBigInt(x/2) * web3.utils.toBigInt(pxx+px0) / web3.utils.toBigInt(web3.utils.toWei("1", "ether"));
        return y;
}


    static async getCoinFromAdopted(x) {
    // x in ether
        const { Web3 } = require('web3');
        const { ethereum } = window;
        const web3 = new Web3(ethereum);

        
        const xwei = web3.utils.toWei(x, "ether");
        const ywei = await this.getCoinFromAmount(xwei);
        const y = web3.utils.fromWei(ywei, "ether");
        return y; //y in ether
}

    static async getCoinToAdopted(y) {
        const { Web3 } = require('web3');
        const { ethereum } = window;
        const web3 = new Web3(ethereum);

        const wocPadInstance = new web3.eth.Contract(woclaunchpadabi, woclaunchpadaddress);

        const p0 = await wocPadInstance.methods.getp0().call();
        const pmax = await wocPadInstance.methods.getpmax().call();
        const dmax= await wocPadInstance.methods.getdmax().call();

        const _ps = web3.utils.fromWei(await this.px(0), "ether");
        const _tga = (Number(pmax)-Number(p0))/Number(dmax);
        const _d = _ps*_ps+2*y*_tga;
        const x=(Math.sqrt(_d)-_ps)/_tga; 
        return x;
}


    static async fromCoinAllowed() {
        const { Web3 } = require('web3');
        const { ethereum } = window;
        const web3 = new Web3(ethereum);

        const wocPadInstance = new web3.eth.Contract(woclaunchpadabi, woclaunchpadaddress);
        const fromCoinAddress = await wocPadInstance.methods.getCoinFromAddress().call();

        const fromCoinInstance = new web3.eth.Contract(erc20abi, fromCoinAddress);
        const address = await Metamasker.getCurrentAccount();

        const allowance = await fromCoinInstance.methods.allowance(address, woclaunchpadaddress).call();
        return allowance;
}

    static async fromCoinApproveAdopted(amount) { //amount in ether
        const { Web3 } = require('web3');
        const { ethereum } = window;
        const web3 = new Web3(ethereum);

        const _amount = web3.utils.toWei(Math.ceil(amount), "ether");
        return await this.fromCoinApprove(_amount);
}

    static async fromCoinApprove(amount) { //amount in wei
        const { Web3 } = require('web3');
        const { ethereum } = window;
        const web3 = new Web3(ethereum);

        const wocPadInstance = new web3.eth.Contract(woclaunchpadabi, woclaunchpadaddress);
        const fromCoinAddress = await wocPadInstance.methods.getCoinFromAddress().call();

        const fromCoinInstance = new web3.eth.Contract(erc20abi, fromCoinAddress);
        const address = await Metamasker.getCurrentAccount();

        const allowance = await this.fromCoinAllowed();
        // console.log("fromCoinApprove, allowance = ", allowance)
        if(allowance<amount) {
            try {
                const trx = await fromCoinInstance.methods
                    .approve(woclaunchpadaddress, amount)
                    .send({ from: address });
                console.log(trx)
                // console.log("fromCoinApprove approve = ", aprret)
                return true;
            } catch (error) {
                console.log(error);
                return false;
            }
        }
        // console.log("fromCoinApprove before final return")
        return true;
}

    static async fromCoinBalance() {
        const { Web3 } = require('web3');
        const { ethereum } = window;
        const web3 = new Web3(ethereum);

        const wocPadInstance = new web3.eth.Contract(woclaunchpadabi, woclaunchpadaddress);
        const fromCoinAddress = await wocPadInstance.methods.getCoinFromAddress().call();

        const fromCoinInstance = new web3.eth.Contract(erc20abi, fromCoinAddress);
        const address = await Metamasker.getCurrentAccount();

        const balance = await fromCoinInstance.methods.balanceOf(address).call();
        return balance;
    }

    static async toCoinBalance() {
        const { Web3 } = require('web3');
        const { ethereum } = window;
        const web3 = new Web3(ethereum);

        const wocPadInstance = new web3.eth.Contract(woclaunchpadabi, woclaunchpadaddress);
        const toCoinAddress = await wocPadInstance.methods.getCoinToAddress().call();

        const toCoinInstance = new web3.eth.Contract(erc20abi, toCoinAddress);
        const address = await Metamasker.getCurrentAccount();

        const balance = await toCoinInstance.methods.balanceOf(address).call();
        return balance;
    }

    static async sellAdopted(amount) {
        const { Web3 } = require('web3');
        const { ethereum } = window;
        const web3 = new Web3(ethereum);

        const _amount = web3.utils.toWei(amount, "ether");
        return await this.sell(_amount);

}
    static async sell(amount) { //amount in wei
        const { Web3 } = require('web3');
        const { ethereum } = window;
        const web3 = new Web3(ethereum);

        const wocPadInstance = new web3.eth.Contract(woclaunchpadabi, woclaunchpadaddress);
        const address = await Metamasker.getCurrentAccount();


        try {
            await wocPadInstance.methods
            .sell(amount)
            .send({ from: address });
        } catch (error) {
            console.log(error);
            return false;
        }
        return true;

}
}
