import ColoredRarityText from '../../../components/nft/ColoredRarityText'
import LandImageAndElement from './LandImageAndElement'
const LandCardSimple = ({land}) => {
  return (
    <div style = {{margin:'5px'}}>
        <ColoredRarityText rarity={land.terrain_type} text={` #${land.landid}`}/>
        <LandImageAndElement land={land}/>
    </div>
)
}

export default LandCardSimple  