import {wocptcabi, wocptcaddress} from './abi.js';
import Metamasker from "./metamasker.js";


export default class WOCPTC {
    static async allowance(owner, spender) {
        const { Web3 } = require('web3');
        const { ethereum } = window;
        const web3 = new Web3(ethereum);
        const wocPtcInstance = new web3.eth.Contract(wocptcabi, wocptcaddress);
        const allowed = await wocPtcInstance.methods.allowance(owner, spender).call();
        
        return(allowed);
    }

    static async approve(spender, amount) {
        const { Web3 } = require('web3');
        const { ethereum } = window;
        const web3 = new Web3(ethereum);
        const wocPtcInstance = new web3.eth.Contract(wocptcabi, wocptcaddress);
        const address = await Metamasker.getCurrentAccount();
        try {
            const trx = await wocPtcInstance.methods
            .approve(spender, amount)
            .send({ from: address });
            console.log(trx)
                return true;
        } catch (error) {
            console.log(error);
            return false;
        }
        return true;
    }


}



// const { Web3 } = require('web3');
// const { ethereum } = window;
// const web3 = new Web3(ethereum);
// const wocPtcInstance = new web3.eth.Contract(wocptcabi, wocptcaddress);

// export async function allowance(owner, spender) {
//     const allowed = await wocPtcInstance.methods.allowance(owner, spender).call();
//     return(allowed);
// }

// export async function approve(spender, amount) {
//     const address = await Metamasker.getCurrentAccount();

//     await wocPtcInstance.methods
//     .approve(spender, amount)
//     .send({ from: address })
//     .then(console.log);
// }