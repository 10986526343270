import {useState} from 'react'
import {
    Stack,
    Button,
    Box,
    Typography,
    Tooltip,
    Link
} from '@mui/material'
import LandCard from './components/LandCard'
import StartHeroTransferMission from '../StartMission/StartHeroTransferMission'
import StartUpgradeElementMission from '../StartMission/StartUpgradeElementMission'
import img from '../../assets/img/forms/land_element_level.png'
import { elementImg } from '../../assets/img/elements'
import {elementTypes} from '../../assets/consts/land_and_hero_attributes'
import {useElementsQuery} from '../../redux/api/landApi'


const LandElements = ({land}) => {
    const [openHeroTransferMission, setOpenHeroTransferMission] = useState(false)
    const [openUpgradeElementMission, setUpgradeElementMission] = useState(false)
    const [element, setElement] = useState(undefined)

    
    const {data, isLoading} = useElementsQuery(land.landid)
    if(isLoading) return 'Loading...'
    console.log('LandElements: data:', data)
    return (<>
        <Stack spacing={10} direction='row'>
        <Box sx={{ 
                flex: 0, 
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                }}> 
            <LandCard land={land}/>
            <Button variant='outlined' onClick={() => setOpenHeroTransferMission(true)} sx={{ width: 200 }}>Transfer hero</Button>

        </Box>
        <Box sx={{ 
                flex: 1, 
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                }}>
             {
                data.elements.map((item, index) => (
                    <Box sx={{ 
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        }}
                        key={index}
                        >
                        <Tooltip title={<>{elementTypes[index]} Elements increase your gold productions. For more details follow <Link href="https://warofcontinents.online/docs/roadmap/epoch1" target="_blank">the link</Link></>} placement='top'>
                        <img src={elementImg[index]} height={50} with={50} style={{ marginRight: 16 }} alt='element' />
                        </Tooltip>
                        <Typography color='secondary' variant='h4'>{item}</Typography>
                    </Box>
                ))
             }
        </Box>
        <Box  sx={{ 
                flex: 1, 
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                alignItems: 'center',
                }}>
            <Button variant='outlined' onClick={() => {setElement(0); setUpgradeElementMission(true)}} sx={{ width: 250 }}>Upgrade Air Element</Button>
            <Button variant='outlined' onClick={() => {setElement(1); setUpgradeElementMission(true)}} sx={{ width: 250 }}>Upgrade Fire Element</Button>
            <Button variant='outlined' onClick={() => {setElement(2); setUpgradeElementMission(true)}} sx={{ width: 250 }}>Upgrade Water Element</Button>
            <Button variant='outlined' onClick={() => {setElement(3); setUpgradeElementMission(true)}} sx={{ width: 250 }}>Upgrade Earth Element</Button>
        </Box>
        <Box sx={{ flex: 1 }}>
            <img src={img} height={300} width={300} style={{borderRadius:'10px', border:'1px solid', 'borderColor':'gray'}} alt='gold storage' />
        </Box>
    </Stack>
    <StartHeroTransferMission open={openHeroTransferMission} setOpen={setOpenHeroTransferMission} landid={land.landid}/>
    <StartUpgradeElementMission open={openUpgradeElementMission} setOpen={setUpgradeElementMission} landid={land.landid} element={element}/>

    </>
  )
}

export default LandElements