import {AppBar, Toolbar, IconButton, Stack, Typography} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { NavbarElements } from './NavbarElements';
import logo from '../../assets/img/logo/logo512.png'



export const Navbar = () => {
    const navigate = useNavigate();
    const [module, setModule] = useState('WOC');

    return (
    <AppBar id="app-bar" position='static' color='inherit'>
        <Toolbar id="woc-toolbar">
            <Stack direction='row' spacing={2} sx={{ flexGrow: 1, alignItems: "center" }}>
                <IconButton 
                    size='small' 
                    color='inherit' 
                    edge='start' 
                    onClick={() => {
                        setModule('WOC');
                        navigate('/')
                    }}
                    style={{  width: 'auto' }}
                >
                    <img src={logo} alt='logo' height={40} width={40}/>
                </IconButton>
                
                <Typography variant='h6' component='div' color='secondary'>
                    {module}
                </Typography>
            </Stack>
    
            <NavbarElements setModule={setModule}/>
        </Toolbar>
    </AppBar>
  )
}
