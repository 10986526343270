import {
    Paper,
    Stack,
    TablePagination,
    Button,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import {useState} from 'react'
import {useHeroesQuery} from '../../redux/api/heroApi'
import HeroCardSimple from './components/HeroCardSimple'
const debug = true;

const Heroes = () => {
    const { data=[], isLoading } = useHeroesQuery()
    
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(8);

    const [open, setOpen] = useState(false);
    const [selectedHero, setSelectedHero] = useState(null);
    
    if (isLoading) return 'Loading...'

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    const handleHeroClick = (hero) => {
        debug && console.log('Heroes::handleHeroClick hero:', hero);
        setSelectedHero(hero);
        setOpen(true);
    }

    const handleDialogClose = () => {
        setOpen(false);
        setSelectedHero(null);
      };

    return (
    <>
      <Paper  sx={{ maxWidth: '60%', margin: '20px auto 0 auto', paddingTop:'10px' }}>
        <Stack direction="row" sx={{ flexWrap: 'wrap', justifyContent: 'center' }}>
                {
                    data?.heroes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((hero) => (
                            <Box key={hero.heroid} onClick={()=>handleHeroClick(hero)}>
                                <HeroCardSimple hero={hero}/>
                            </Box>
                    ))
                }
        </Stack>
        <TablePagination
            component="div"
            count={data?.heroes?.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10]}
      />
      </Paper>

    <Dialog open={open} onClose={handleDialogClose}>
    {selectedHero?
        <>
        {/* <DialogTitle>{selectedHero?.name}#{selectedHero?.heroid}</DialogTitle> */}
        <DialogContent>
            <HeroCardSimple hero={selectedHero}/>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleDialogClose}>Close</Button>
        </DialogActions>
        </>
    :null
    }
    </Dialog>
    </>
    )
}

export default Heroes