import { DESIRED_CHAIN_ID } from "./chain";



export default class Metamasker {

    static isMetaMaskInstalled() {
        const { ethereum } = window;
        const isMMInstalled = Boolean(ethereum && ethereum.isMetaMask);
        console.log(isMMInstalled?'Metamask installed':'Metamask not installed');
        return isMMInstalled;
    }

    static async getCurrentChainId(){
        const { ethereum } = window;
        const chainId = await ethereum.request({ method: "eth_chainId" });
        console.log('Current chainID is:', chainId);
        return (chainId);
    }

    static getDesiredChainId() {
        console.log('Desired chain id is:', DESIRED_CHAIN_ID);
        return (DESIRED_CHAIN_ID);
    }

    static async switchToDesiredChain(){
        const { ethereum } = window;
        const desirerdChainId = this.getDesiredChainId();
        const currChainId = await this.getCurrentChainId();
        if(currChainId.toString(16)!==desirerdChainId.toString(16)){
            await ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: "0x" + desirerdChainId.toString(16) }],
            });
            console.log('Switched to desired chain id:', desirerdChainId)
        } else {
            console.log("We are already on desired chain id")
        }
    }

    static async getCurrentAccount() {
        const { ethereum } = window;
        const accounts = await ethereum.request({ method: "eth_requestAccounts" });
        // console.log('Current address is: ', accounts[0], typeof accounts[0]);
        return accounts[0];
    }

}