import { resApi } from './resApi';

export const lakanApi = resApi.injectEndpoints({
    overrideExisting: true,
    endpoints: (builder) => ({
        coinBalance: builder.query({
            query: () => `/lakan/balances/coins`,
        }),

        
        // вторая версия через прокси
        userBalance: builder.query({
            query: () => `/khalid/user/inventory/cookie`,
        }),
    }),


});

export const {useCoinBalanceQuery, useUserBalanceQuery} = lakanApi;