import {
    Button,
    Stack
  } from '@mui/material';

import {heroImages} from '../../../assets/img/heroes'

const HeroButton = ({hero, setSelectedHero}) => {
  return (
    <Button variant="text" sx={{ width: '100%', justifyContent: 'flex-start' }} disabled={hero.busy} onClick={()=>{setSelectedHero(hero)}}>
            <img src={heroImages[hero.sex][hero.rarity]} alt={hero.name} width="20" height="35" style={{ marginRight: '20px' }} />
            hero #{hero.heroid} {hero.landid>0 ? `from Land #${hero.landid}` : ''}
    </Button>
  )
}

export default HeroButton