import { Box } from '@mui/material';
import { keyframes } from '@emotion/react';

import land0 from '../../../assets/img/lands/woc-land-0-valley-500x500.png';
import land1 from '../../../assets/img/lands/woc-land-1-forest-500x500.png';
import land2 from '../../../assets/img/lands/woc-land-2-mountain-500x500.png';
import land3 from '../../../assets/img/lands/woc-land-3-river-500x500.png';
import land4 from '../../../assets/img/lands/woc-land-4-arctic-500x500.png';

const animationDuration = 2;
const cycleAnimation = keyframes`
  0% { background-image: url(${land0}); }
  20% { background-image: url(${land1}); }
  40% { background-image: url(${land2}); }
  60% { background-image: url(${land3}); }
  80% { background-image: url(${land4}); }
  100% { background-image: url(${land0}); }
`;


const LandImageCycle = () => {
  return (
    <Box
      sx={{
        width: '200px', // ширина контейнера
        height: '200px', // высота контейнера
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        animation: `${cycleAnimation} ${animationDuration}s linear infinite`,
        borderRadius: '10px'
      }}
    />
  )
}

export default LandImageCycle