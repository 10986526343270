import React from 'react'
import {useUserBalanceQuery} from '../../redux/api/lakanApi'
import {Table,
        TableBody,
        TableCell,
        TableContainer,
        TableHead,
        TableRow,
        Paper,
        Box,
        Stack
} from '@mui/material';
import img from '../../assets/img/forms/user_inventory.png'

const debug = true;

const MyMedals = () => {
  const { data=[], isLoading } = useUserBalanceQuery()
  if (isLoading) return 'Loading...'

  // console.log(data)

  return (
    <Stack spacing={10} direction='row' justifyContent="space-between">
        <Box sx={{ 
                flex: 0, 
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                }}> 
User Inventory
        </Box>
        <Box sx={{ 
                flex: 1, 
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                }}> 
          <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell align="right">Qty</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                data?.map((item) => (
                  <TableRow key={item.asset_code}>
                    <TableCell component="th" scope="row">
                      {item.asset_name}
                    </TableCell>
                    <TableCell align="right">{item.balance_dr}</TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
          </TableContainer>
    </Box>
    <Box sx={{ flex: 1 }}>
            <img src={img} height={300} width={300} style={{borderRadius:'10px', border:'1px solid', 'borderColor':'gray'}} alt='inventory' />
        </Box>
    </Stack>
  )
}

export default MyMedals